@import 'src/styles/_variables';

.app-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility .3s ease-in-out .3s;
  padding: 75px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: #000000bd;
  z-index: @z-index-app-menu;
}

.app-menu.allow-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.app-menu.opened {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s;
  backdrop-filter: blur(20px);
}
@primary-color: #1DA57A;
.rounded-checkbox {
  .ant-checkbox {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #1DA57A;
  }

  .ant-checkbox-inner {
    border: none;
  }
}
@primary-color: #1DA57A;
@import 'src/styles/_variables';

.filter-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.filter-wrapper {
  .filter-item {
    margin-right: 10px;
  }

  .filter-item:last-child {
    margin-right: 0;
  }

  @media @xs-320 {
    width: 100%;
  }
}
@primary-color: #1DA57A;
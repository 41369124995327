@import 'src/styles/_variables';

.forgot-password-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media @xs-320 {
    padding: 0 20px;
    width: 100vw;
  }

  @media @sm-768 {
    padding: 20px;
    width: 360px;
  }
}
@primary-color: #1DA57A;
.full-width {
  width: 100%;
}

.button-default {
  background-color: #0e0e0e;
  color: white;
  border: none;
}

.ant-btn.button-transparent {
  background-color: transparent;
  color: white;
  border: 1px solid white;

  &:focus {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }

  &:hover {
    background-color: transparent;
    color: white;
    border: 1px solid white;

    @media (hover: hover) and (pointer: fine) {
      background-color: rgba(255, 255, 255, 0.1);
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.ant-btn.button-invisible {
  background-color: transparent;
  color: white;
  border: none;

  &:focus {
    background-color: transparent;
    color: white;
    border: none;
  }

  &:hover {
    background-color: transparent;
    color: white;
    border: none;
  }
}

.ant-btn.active {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transform: scale(.9);
}
@primary-color: #1DA57A;
@import 'src/styles/_variables';

.notification-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 10px 10px 0;
  background-color: #000000;
  border-radius: 4px;
  bottom: -300px;
  opacity: 0;
  width: 0;
  z-index: @z-index-notification;
  transition: bottom .4s cubic-bezier(0.37, 0.55, 0.12, 1.36) .1s,
              opacity .3s ease-in-out,
              width .2s linear .1s;
  overflow: hidden;
  max-height: 150px;
}

.notification-container.info {
  border-left: 10px solid rgba(153, 138, 64, 0.9);
}

.notification-container.success {
  border-left: 10px solid rgba(90, 144, 65, 0.9);
}

.notification-container.error {
  border-left: 10px solid rgba(179, 79, 79, 0.9);
}

.notification-container.visible {
  animation: .3s cubic-bezier(0.37, 0.55, 0.12, 1.36) appearance;
  bottom: 10px;
  opacity: .9;

  @media @xs-320 {
    width: 300px;
  }

  @media @md-1024 {
    width: 500px;
  }
}

@keyframes appearance {
  0% {
    bottom: -300px;
    opacity: 0;
    width: 0;
  }

  90% {
    opacity: .7;
  }

  100% {
    bottom: 10px;
    opacity: .9;

    @media @xs-320 {
      width: 315px;
    }

    @media @sm-768 {
      width: 500px;
    }

  }
}

.close-icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
@primary-color: #1DA57A;
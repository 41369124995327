@import 'src/styles/_variables';

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media @xs-320 {
    padding: 0 20px;
    width: 100vw;
  }

  @media @sm-768 {
    padding: 20px;
    width: 360px;
  }
}
@primary-color: #1DA57A;
.nova-poshta-fields {
  visibility: visible;
  opacity: 1;
  max-height: 300px;
  transition:
          max-height .3s linear,
          visibility 0s ease-in-out .3s,
          opacity .3s ease-in-out .3s;
}

.nova-poshta-fields.hidden {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition:
          opacity .3s ease-in-out,
          visibility 0s ease-in-out .3s,
          max-height .3s linear .3s;
}
@primary-color: #1DA57A;
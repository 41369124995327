@import 'src/styles/_variables';

.page-container {
  @media @xs-320 {
    padding: 75px 20px 75px 20px;
  }

  @media @sm-768 {
    padding: 75px 50px 75px 50px;
  }

}

.page-container.full-height {
  height: 100vh;
}

@primary-color: #1DA57A;
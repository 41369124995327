@import 'src/styles/_variables';

h1 {
  margin: 0;
  letter-spacing: 0.7px;
  @media @lg-1440 {
    font-size: 2em;
    letter-spacing: 3px;
  }
  @media @md-1024 {
    font-size: 1.8em;
    letter-spacing: 2px;
  }
  @media @sm-768 {
    font-size: 1.7em;
    letter-spacing: 1px;
  }
  @media @xs-320 {
    font-size: 1.7em;
    letter-spacing: 1px;
  }
  @media @phones {
    font-size: 1.7em;
    letter-spacing: 1px;
  }
}

h2 {
  margin: 0;
  letter-spacing: 0.6px;
  @media @lg-1440 {
    font-size: 1.8em;
    letter-spacing: 2px;
  }
  @media @md-1024, @tablets {
    font-size: 1.6em;
    letter-spacing: 1px;
  }
  @media @sm-768 {
    font-size: 1.5em;
    letter-spacing: 1px;
  }
  @media @xs-320, @phones {
    font-size: 1.5em;
    letter-spacing: 1px;
  }
}

h3 {
  margin: 0;
  letter-spacing: 0.5px;
  @media @lg-1440, @tablets {
    font-size: 1.6em;
    letter-spacing: 2px;
  }
  @media @md-1024 {
    font-size: 1.4em;
    letter-spacing: 1px;
  }
  @media @sm-768, @phones {
    font-size: 1.2em;
    letter-spacing: 1px;
  }
  @media @xs-320 {
    font-size: 1.2em;
  }
}

h4 {
  margin: 0;
  letter-spacing: 0.4px;
  @media @lg-1440, @tablets {
    font-size: 1.4em;
  }
  @media @md-1024 {
    font-size: 1.2em;
  }
  @media @sm-768 {
    font-size: 1.1em;
  }
  @media @xs-320 {
    font-size: 1.1em;
  }
  @media @phones {
    font-size: 1.1em;
  }
}

h5 {
  margin: 0;
  letter-spacing: 0.3px;
  @media @lg-1440, @tablets {
    font-size: 1.2em;
  }
  @media @md-1024 {
    font-size: 1.1em;
  }
  @media @sm-768 {
    font-size: 1em;
  }
  @media @xs-320 {
    font-size: 1em;
  }
  @media @phones {
    font-size: 1em;
  }
}

h6 {
  margin: 0;
  letter-spacing: 0.2px;
  @media @lg-1440, @tablets {
    font-size: 1em;
  }
  @media @md-1024 {
    font-size: .9em;
  }
  @media @sm-768 {
    font-size: .8em;
  }
  @media @xs-320 {
    font-size: .8em;
  }
  @media @phones {
    font-size: .8em;
  }
}

p {
  margin: 0;
  @media @lg-1440, @tablets {
    font-size: 1.3em;
    letter-spacing: 1px;
  }
  @media @md-1024 {
    font-size: 1.1em;
    letter-spacing: .5px;
  }
  @media @sm-768 {
    font-size: 1em;
    letter-spacing: .5px;
  }
  @media @xs-320 {
    font-size: 1em;
    letter-spacing: .5px;
  }
  @media @phones {
    font-size: 1em;
    letter-spacing: .5px;
  }
}
@primary-color: #1DA57A;
@keyframes loadingAnimation {
  0% {
    height: 0;
    width: 0;
    right: -2px;
    top: 0;
  }

  80% {
    height: 18px;
    width: 18px;
    right: -11px;
    top: -9px;
  }

  100% {
    height: 16px;
    width: 16px;
    right: -10px;
    top: -8px;
  }
}

.badge {
  animation: .2s ease-in-out loadingAnimation;
  background-color: black;
  height: 16px;
  width: 16px;
  color: white;
  font-size: 10px;
  position: absolute;
  top: -8px;
  right: -10px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@primary-color: #1DA57A;
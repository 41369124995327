.close-wrapper {
  position: relative;
  width: 15px;
  height: 15px;
  cursor: pointer;

  .line1 {
    position: absolute;
    top: 30%;
    left: 0;
    width: 16px;
    height: 2px;
    transition: width .3s, transform .3s;
    transform: rotate(45deg) translateY(2px) translateX(1px);
  }

  .line2 {
    position: absolute;
    bottom: 30%;
    width: 16px;
    height: 2px;
    left: 0;
    transition: width .3s, transform .3s;
    transform: rotate(-45deg) translateY(-2px) translateX(1px);
  }
}

.close-wrapper.active {
  transform: translate(5%, 5%) scale(.95);
}
@primary-color: #1DA57A;
.react-tel-input {
  .phone-input.white {
    background-color: transparent;
    color: white;
    font-size: 16px;
  }

  .phone-input.black {
    background-color: white;
    color: black;
    font-size: 16px;
  }
}
@primary-color: #1DA57A;
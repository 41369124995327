@import 'src/styles/_variables';

.checkout-container {
  position: relative;

  .country-item {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
  }

  .country-item-highlighter {
    position: absolute;
    width: 30%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    left: 10%;
    transition: left .3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .country-item-highlighter.right {
    position: absolute;
    width: 30%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    left: 60%;
    transition: left .3s cubic-bezier(0.4, 0, 0.2, 1);
  }

}
@primary-color: #1DA57A;
.burger-wrapper {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;

  .line1 {
    position: absolute;
    top: 30%;
    left: 0;
    width: 25px;
    height: 2px;
    transition: width .3s, transform .3s;
  }

  .line2 {
    position: absolute;
    bottom: 30%;
    width: 25px;
    height: 2px;
    left: 0;
    transition: width .3s, transform .3s;
  }

  .line3 {
    position: absolute;
    bottom: 45%;
    width: 0;
    height: 2px;
    left: -2px;
    transition: width 0.3s, opacity 0.3s;
    opacity: 0;
  }
}

.burger-wrapper.active {
  transform: translate(5%, 5%) scale(.95);
}

.burger-wrapper.opened {
  & > .line1 {
    width: 26px;
    transform: rotate(45deg) translateY(4px) translateX(2px);
    transition: width .3s, transform .3s;
  }

  & > .line2 {
    width: 26px;
    transform: rotate(-45deg) translateY(-4px) translateX(2px);
    transition: width .3s, transform .3s;
  }
}

//.burger-wrapper.opened {
//  & > .line1 {
//    width: 13px;
//    transform: rotate(45deg) translateY(9px) translateX(2px);
//    transition: width .3s, transform .3s;
//  }
//
//  & > .line2 {
//    width: 13px;
//    transform: rotate(-45deg) translateY(-9px) translateX(2px);
//    transition: width .3s, transform .3s;
//  }
//
//  & > .line3 {
//    width: 25px;
//    opacity: 1;
//    transition: width .3s, opacity .3s;
//  }
//}
@primary-color: #1DA57A;
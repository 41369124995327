@import 'src/styles/_variables';

.total-container {
  position: sticky;
  bottom: 0;
  padding: 20px 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.61);
  border-radius: 2px;
}
@primary-color: #1DA57A;
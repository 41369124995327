@import 'src/styles/_variables';

.skeleton-white {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #dadada, #f5f5f5, #dadada);
  animation: animation-bzdot9 2s ease-in-out infinite;
}

.skeleton-dark {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #414141, #252525, #414141);
  animation: animation-bzdot9 2s ease-in-out infinite;
}

@primary-color: #1DA57A;
@import 'src/styles/_variables';

.cart-container {
  display: flex;
  justify-content: center;
}

.cart-content {
  width: 100%;
  overflow-x: hidden;

  @media @md-1024 {
    width: 400px;
  }

  .cart-item {
    height: 150px;
    position: relative;

    .close-icon-wrapper {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .cart-item.removed {
    animation: itemRemoval .3s linear;
  }

  .product-photo-container {
    @media @xs-320 {
      width: 100px;
      height: 150px;
    }

    @media @md-1024 {
      width: 100px;
      height: 150px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-wrapper {
    flex-direction: column;
    max-width: 56%;

    .color {
      width: 20px;
      height: 20px;
      border: 1px solid white;
    }

    .size {
      height: 20px;
      background-color: white;
      padding: 0 10px;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: #ffffff54;
    margin: 20px 0;
  }
}

.cart-steps {
  display: flex;
  justify-content: space-between;

  @media @xs-320 {
    width: 200%;
  }

  @media @md-1024 {
    width: 800px;
  }
}

.cart-steps.step-0 {
  transform: translateX(0%);
  transition: transform .5s cubic-bezier(0.49, 1.46, 1, 1);
}

.cart-steps.step-1 {
  transform: translateX(-50%);
  transition: transform .5s cubic-bezier(0.49, 1.46, 1, 1);
}

.checkout-item {
  height: 0;
  overflow: hidden;

  @media @xs-320 {
    width: 100%;
  }

  @media @md-1024 {
    width: 400px;
    padding: 0 10px;
  }
}

.checkout-item.active {
  height: auto;
  overflow: auto;
}

@keyframes itemRemoval {
  0% {
    transform: translateX(0);
    opacity: 1;
    max-height: 300px;
  }

  50% {
    transform: translateX(-120%);
    opacity: 1;
    max-height: 300px;
  }

  100% {
    transform: translateX(-120%);
    opacity: 0;
    max-height: 0;
  }
}
@primary-color: #1DA57A;
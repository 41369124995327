.ant-select,
.ant-select-auto-complete,
.ant-select-single,
.ant-select-customize-input,
.ant-select-show-search {
  width: 100%;

  .white input {
    background-color: transparent;
    color: white;
    font-size: 16px;
  }

  .black input {
    background-color: transparent;
    color: black;
    font-size: 16px;
  }
}
@primary-color: #1DA57A;
@import 'src/styles/_variables';

.login-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  transition: opacity .3s, visibility .3s ease-in-out .3s;

  display: flex;
  justify-content: center;
  align-items: center;

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 520px;
    overflow: hidden;

    @media @xs-320 {
      width: 100vw;
    }

    @media @sm-768 {
      width: 360px;
      border: 1px solid #ffffff3d;
    }

    .views.login {
      @media @xs-320 {
        width: 300vw;
      }

      @media @sm-768 {
        width: 1080px;
        border: 1px solid #ffffff3d;
      }

      height: 100%;
      display: flex;
      transform: translateX(0);
      transition: transform .2s linear;
    }

    .views.registration {
      @media @xs-320 {
        width: 300vw;
        transform: translateX(-100vw);
      }

      @media @sm-768 {
        width: 1080px;
        border: 1px solid #ffffff3d;
        transform: translateX(-360px);
      }

      height: 100%;
      width: 1080px;
      display: flex;
      transition: transform .2s linear;
    }

    .views.forgot-password {
      @media @xs-320 {
        width: 300vw;
        transform: translateX(-200vw);
      }

      @media @sm-768 {
        width: 1080px;
        border: 1px solid #ffffff3d;
        transform: translateX(-720px);
      }

      height: 100%;
      width: 1080px;
      display: flex;
      transition: transform .2s linear;
    }
  }
}
@primary-color: #1DA57A;
.switch-item {
  display: flex!important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}

.switch-item-highlighter {
  position: absolute;
  width: 35%;
  top: 0;
  bottom: 0;
  background-color: white;
  border-radius: 15px;
  left: 7.5%;
}

.switch-item-highlighter.left {
  animation: moveLeft 0.5s cubic-bezier(0.82, 0.19, 0.13, 0.99);
}

.switch-item-highlighter.right {
  position: absolute;
  width: 35%;
  height: 100%;
  background-color: white;
  border-radius: 15px;
  left: 57.5%;
  animation: moveRight .5s cubic-bezier(0.82, 0.19, 0.13, 0.99);
}

@keyframes moveRight {
  0% {
    width: 35%;
    left: 7.5%;
  }

  50% {
    width: 85%;
    left: 7.5%;
  }

  100% {
    width: 35%;
    left: 57.5%;
  }
}

@keyframes moveLeft {
  0% {
    width: 35%;
    left: 57.5%;
  }

  50% {
    width: 85%;
    left: 7.5%;
  }

  100% {
    width: 35%;
    left: 7.5%;
  }
}
@primary-color: #1DA57A;
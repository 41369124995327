@import 'node_modules/antd/dist/antd.less';
@import '_variables';

@font-face {
  font-family: FuturaLight;
  src: local('FuturaLight'), url("../assets/fonts/futuralightc.woff2") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FuturaBook;
  src: local('FuturaBook'), url("../assets/fonts/futurabookc.woff2") format("woff");
  font-display: swap;
}

body {
  margin: 0;
  font-family: FuturaLight, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @app-background;
}

code {
  font-family: FuturaLight, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-size: 16px!important;
}
@primary-color: #1DA57A;
@import 'src/styles/_variables';

.clipboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.copied-text {
  background-color: @app-background;
  padding: 2px 8px;
  border-radius: 10px;
  animation: 1s linear copiedTextAnimation;
  position: absolute;
  top: -25px;
}

@keyframes copiedTextAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
@primary-color: #1DA57A;
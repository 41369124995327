.img {}

.img.active {
  transform: translate(5%, 5%) scale(.95);
  //transition: transform .05s ease-in-out;
}

.img.bw {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
@primary-color: #1DA57A;